<template>
  <div class="nft_detail">
    <div class="info_detail">
      <h3>{{$t('nft_history')}}</h3>
      <table class="table_wrap" width="100%"  border="0" cellspacing="0" cellpadding="0">
        <colgroup>
          <col style="max-width: 25%; width: 25%;">
          <col style="max-width: 16.66%; width: 16.66%;">
          <col style="max-width: 16.66%; width: 16.66%;">
          <col style="max-width: 16.66%; width: 16.66%;">
          <col style="max-width: 25%; width: 25%;">
        </colgroup>
        <tr height="52"  class="t_top">
          <th scope="col" class="h_t_th">Event</th>
          <th scope="col" class="h_t_th">Price</th>
          <th scope="col" class="h_t_th">From</th>
          <th scope="col" class="h_t_th">To</th>
          <th scope="col" class="h_t_th">Date</th>
        </tr>

        <!--tr에 active 클래스 추가시 border-bottom : none;-->
        <tr height="75" class="table_content">
          <td width="25%">{{$t('s_bid')}}</td>
          <td width="16.66%">-</td>
          <td width="16.66%">nickname</td>
          <td width="16.66%">nickname</td>
          <td width="25%">2021-07-22 10:33</td>
        </tr>
        <tr height="75" class="table_content">
          <td width="25%">{{$t('bid_enroll')}}</td>
          <td width="16.66%">-</td>
          <td width="16.66%">nickname</td>
          <td width="16.66%">nickname</td>
          <td width="25%">2021-07-22 10:33</td>
        </tr>
        <tr height="75" class="table_content">
          <td width="25%">{{$t('buy_license')}}</td>
          <td width="16.66%">10,000</td>
          <td width="16.66%">nickname</td>
          <td width="16.66%">nickname</td>
          <td width="25%">2021-07-22 10:33</td>
        </tr>
        <tr height="75" class="table_content">
          <td width="25%">{{$t('create_nft')}}</td>
          <td width="16.66%">-</td>
          <td width="16.66%">nickname</td>
          <td width="16.66%">nickname</td>
          <td width="25%">2021-07-22 10:33</td>
        </tr>
        <tr height="75" class="table_content">
          <td width="25%">{{$t('bid_enroll')}}</td>
          <td width="16.66%">-</td>
          <td width="16.66%">nickname</td>
          <td width="16.66%">nickname</td>
          <td width="25%">2021-07-22 10:33</td>
        </tr>
        <tr height="75" class="table_content">
          <td width="25%">{{$t('buy_license')}}</td>
          <td width="16.66%">10,000</td>
          <td width="16.66%">nickname</td>
          <td width="16.66%">nickname</td>
          <td width="25%">2021-07-22 10:33</td>
        </tr>
        <tr height="75" class="table_content">
          <td width="25%">{{$t('create_nft')}}</td>
          <td width="16.66%">-</td>
          <td width="16.66%">nickname</td>
          <td width="16.66%">nickname</td>
          <td width="25%">2021-07-22 10:33</td>
        </tr>
        <tr height="75" class="table_content">
          <td width="25%">{{$t('buy_license')}}</td>
          <td width="16.66%">10,000</td>
          <td width="16.66%">nickname</td>
          <td width="16.66%">nickname</td>
          <td width="25%">2021-07-22 10:33</td>
        </tr>
        <tr height="75" class="table_content">
          <td width="25%">{{$t('create_nft')}}</td>
          <td width="16.66%">-</td>
          <td width="16.66%">nickname</td>
          <td width="16.66%">nickname</td>
          <td width="25%">2021-07-22 10:33</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "HistoryListDefaultLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
      idx: 1,
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    movePage(path) {
      this.$router.push(`${path}/history`);
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
